<template>
  <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
    <div class="fontSize20gay text-left">题目编辑</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
          <el-form-item label="分数" prop="score" size="large" label-width="120px">
            <el-input v-model="pageForm.score"
                      autocomplete="off"
                      placeholder="请填写分数"
                      class="room-input-tl"
                      @blur="floatParse(pageForm.score, val => pageForm.score = val)" />
          </el-form-item>
          <el-form-item label="题目" prop="title" size="large" label-width="120px">
            <el-input v-model="pageForm.title"
                      autocomplete="off"
                      placeholder="请填写题目"
                      class="room-input-tl" />
          </el-form-item>
          <el-form-item label="选项" prop="optionList" size="large" label-width="120px">
            <div style="width: 100%;">
              <el-row v-for="iter in pageForm.optionList"
                      :key="iter.sort">
                <el-col :span="1"> {{ indexMapper(iter.sort) }} </el-col>
                <el-col :span="21">
                  <el-input v-model="iter.title"></el-input>
                </el-col>
                <el-col :span="2" class="flex justify-center align-center " style=" font-size: 24px;">
                  <el-button icon="Delete"
                             @click="removeOption(iter.sort)"/>
                </el-col>
              </el-row>
              <div class="marginTop10px flex justify-end">
                <button class="subBtn"
                        @click="addOptions">添加选项</button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="正确答案" prop="correctValue" size="large" label-width="120px">
            <el-select v-model="pageForm.correctValue"
                       @change="setCorrect"
                       filterable
                       class="room-select-tl"
                       placeholder="选择正确答案"
                       size="large">
              <el-option v-for="item in pageForm.optionList"
                         :key="item.title"
                         :label="indexMapper(item.sort)"
                         :value="item.sort"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="题目分类" prop="questionTypeId" size="large" label-width="120px">
            <el-select v-model="pageForm.questionTypeId"
                       @change="$refs.formRef.validateField('questionTypeId')"
                       filterable
                       class="room-select-tl"
                       placeholder="选择分类"
                       size="large">
              <el-option v-for="item in classificationOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="marginTop15px">
      <button class="subBtn" @click="subData">保存</button>
      <button class="qxBtn" @click="qxBtn">取消</button>
    </div>
  </div>
</template>

<script>
import {addQuestion, editQuestion, getAllQuestionType, queryQuestion} from "@/api/examination";
import {floatParse} from "@/utils/common";
export default {
  name: "create",
  data(){
    const validateOptionList = (rule, value, callback) => {
      if (value.length <= 26) {
        if (value.length === 1 && !value[0].title) {
          callback(new Error('请至少填写一个选项'))
          return
        }
        callback()
      } else {
        callback(new Error('最多只支持26个选项'))
      }
    }
    return {
      classificationOptions: [],
      pageForm: {
        // 题目类型
        questionTypeId: '',
        // 分数
        score: '',
        // 题干
        title: '',
        // 单选题默认
        type: 0,
        // 选项
        optionList: [],
        // 正确答案 *
        correctValue: ''
      },
      pageFormRules: {
        optionList: [
          {required: true, message: '请添加选项', trigger: 'blur'},
          {validator: validateOptionList, trigger: 'blur'}
        ],
        questionTypeId: [
          {required: true, message: '请选择题目类型', trigger: 'blur'},
        ],
        score: [
          {required: true, message: '请填写题目分数', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '请填写题目', trigger: 'blur'},
        ],
        correctValue: [
          {required: true, message: '请选择正确答案', trigger: 'blur'},
        ]
      },
      id: null,
    }
  },
  watch: {

  },
  mounted() {
    this.getOption()
    const id = this.$route.params.id
    if (id) {
      this.id = id
      this.getQuestionInfo()
    }
  },
  methods: {
    floatParse,
    getOption(){
      // 获取分类
      getAllQuestionType().then(res => {
        res.data.forEach(iter => {
          this.classificationOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    getQuestionInfo(){
      queryQuestion(this.id).then(res => {
        if (res.success) {
          const { questionTypeId, score, title, type, options } = res.data
          this.pageForm = { ...this.pageForm, questionTypeId, score, title, type}
          this.pageForm.optionList.length = 0
          if (Array.isArray(options)) {
            options.forEach(iter => {
              const { isAnswer, title, sort } = iter
              if (isAnswer) {
                this.pageForm.correctValue = sort
              }
              this.pageForm.optionList.push({
                isAnswer,
                title,
                sort
              })
            })
          }
        }
      })
    },
    /**
     * 添加选项
     */
    addOptions(){
      const sort = this.pageForm.optionList.length
      this.pageForm.optionList.push({
          // 是否答案
          isAnswer: false,
          // 选项名称
          title: '',
          // 选项
          sort: sort,
      })
    },
    /**
     * 删除选项
     */
    removeOption(index){
      this.pageForm.optionList.splice(index, 1)
      this.pageForm.optionList.map( (iter, index) => { iter.sort = index })
      this.pageForm.correctValue = ''
    },
    /**
     * 设置正确答案
     */
    setCorrect(index){
      this.pageForm.optionList.map(iter => {
        iter.isAnswer = false
      })
      this.pageForm.optionList[index].isAnswer = true

      this.$refs.formRef.validateField('correctValue')
    },
    indexMapper(index){
      return String.fromCharCode(index + 65)
    },
    subData(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          // 删除空选项
          const resOptionList = []
          this.pageForm.optionList.forEach(iter => {
            if (iter.title) {
              resOptionList.push(iter)
            }
          })
          this.pageForm.optionList = resOptionList

          // 提交类型
          const handler = this.id ? editQuestion : addQuestion
          if (this.id) {
            this.pageForm.id = this.id
          }

          handler(this.pageForm).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              setTimeout(() => {
                this.$root.useRouter.replace('/question/list')
              }, 500)
            }else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>